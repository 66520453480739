// @flow

import { rem } from 'polished';
import { graphql, Link } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import {
  Layout,
  MainNewsLink,
  NewsLink,
  Section,
  TabMenu,
  TextHeading,
} from '../components';
import { Color, Dimen } from '../constants';
import { capitalize, unwrapConnection } from '../util';

import type { NewsIndexPageTemplateQuery } from '../__generated__/NewsIndexPageTemplateQuery';

type Props = $ReadOnly<{|
  data: NewsIndexPageTemplateQuery,
  pageContext: $ReadOnly<{|
    currentPage: number,
    mode: 'host' | 'guest',
    totalPages: number,
  |}>,
|}>;

const PER_PAGE = 6;

const NewsPage = ({
  data: { newsConnection },
  pageContext: { currentPage, mode, totalPages },
}: Props) => {
  const allArticles = unwrapConnection(newsConnection).filter(
    article => article[mode]
  );
  // Add 1 again to compensate for featured article on first page
  const pageStart = (currentPage - 1) * PER_PAGE + (currentPage > 1 ? 1 : 0);
  const pageEnd = pageStart + PER_PAGE - 1 + (currentPage > 1 ? 0 : 1);
  // console.log(currentPage, pageStart, pageEnd);
  const pageArticles = allArticles.filter(
    (_, i) => i >= pageStart && i <= pageEnd
  );

  const firstArticle =
    currentPage === 1 && pageArticles.length ? pageArticles[0] : null;
  const restArticles = firstArticle
    ? pageArticles.filter(article => article !== firstArticle)
    : pageArticles;

  const pageLinks = [];
  for (let i = 1; i <= totalPages; i++) {
    pageLinks.push(
      <StyledPageLink
        key={i}
        to={`/news/${mode}${i === 1 ? '' : `/${i}`}`}
        current={i === currentPage}
      >
        {i}
      </StyledPageLink>
    );
  }

  return (
    <Layout>
      <TabMenu
        links={[
          {
            url: '/news/host',
            label: `View Host News`,
            highlight: mode === 'host',
          },
          {
            url: '/news/guest',
            label: 'View Guest News',
            highlight: mode === 'guest',
          },
        ]}
      />
      <Section>
        <StyledLeadingText>
          <TextHeading level={1} tag="h1">
            Latest {capitalize(mode)} News
          </TextHeading>
        </StyledLeadingText>
      </Section>
      {firstArticle && <MainNewsLink article={firstArticle} />}
      <Section>
        <StyledFeature>
          {restArticles.map(article => (
            <NewsLink key={article.id} article={article} />
          ))}
        </StyledFeature>
      </Section>
      {pageLinks.length > 1 && (
        <Section backgroundColor="grey">{pageLinks}</Section>
      )}
    </Layout>
  );
};

export default NewsPage;

export const query = graphql`
  query NewsIndexPageTemplateQuery {
    newsConnection: allDatoCmsNewsArticle(
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          id
          guest
          host
          ...MainNewsLink_article
          ...NewsLink_article
        }
      }
    }
  }
`;

const StyledLeadingText = styled('div')`
  padding: ${rem(Dimen.spacing * 2)} 0;
  text-align: center;
`;

const StyledFeature = styled('div')`
  margin: ${rem(Dimen.spacing / 2)}
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  text-align: center;

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;

    > div {
      margin-bottom: ${rem(Dimen.spacing)};
    }
  }
`;

const StyledPageLink = styled(Link)`
  display: inline-block;
  padding: ${rem(10)};
  margin: ${rem(30)} 0;
  ${p => p.current && `color: ${Color.vibrantBlue}`};
`;
